import React from 'react';
import { motion } from 'framer-motion';

export const Landing = () => {
  return (
    <div className="relative min-h-screen w-full overflow-hidden bg-[#0D0D0D]">
      {/* Background Image */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2.5 }}
        className="absolute inset-0"
      >
        <img
          src="/images/landing4.png"
          alt="Landing Background"
          className="w-full h-full object-cover"
        />
      </motion.div>

      {/* Three Column Layout */}
      <div className="relative z-20 flex justify-between items-center min-h-screen px-12 max-w-7xl mx-auto">
        {/* Left Logo (Telegram) */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.5 }}
          className="flex items-center"
        >
          <motion.a
            href="https://t.me/Apollofun_bot"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.2 }}
          >
            <img 
              src="/images/telegram.png" 
              alt="Telegram" 
              className="w-48 h-48"
            />
          </motion.a>
        </motion.div>

        {/* Right Logo (Injective) */}
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.5 }}
          className="flex items-center"
        >
          <motion.a
            href="https://injective.com/"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.2 }}
            className="relative"
          >
            <img 
              src="/images/injective.png" 
              alt="Injective" 
              className="w-48 h-48 relative z-10"
            />
          </motion.a>
        </motion.div>
      </div>
    </div>
  );
}; 